@import "styles/variables";

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  text-align: center;
  padding: $padding-md * 4 $padding-md;

  img {
    max-width: 100%;
  }

  h1 {
    line-height: 1;
    margin: {
      top: $margin-md * 2;
      bottom: $margin-md;
    }
    color: $brand-middle-grey;
    font: {
      weight: 700;
      size: $font-lg * 3;
    }

    @media (max-width: $mobile-width) {
      font-size: $font-lg * 2;
    }
  }

  p {
    margin-bottom: $margin-md * 3;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: $margin-sm;
    }
  }
}